<template>
  <v-layout class="fill-height" column>
    <v-layout class="fill-height">
      <div style="width: 340px; height: 100%; flex: none">
        <v-card class="custom-card-bg-1 overflow-y-hidden" height="100%" width="100%">
          <v-layout class="fill-height" style="position: relative" column>
            <v-card class="custom-card-bg-0 overflow-y-auto overflow-x-hidden" style="text-align: center" width="100%">
              <v-card-title class="py-3">
                AOI Information
                <v-spacer />
              </v-card-title>
              <v-card-text v-if="AOIData" class="pb-1">
                <v-layout align-start class="pl-2" column>
                  <div
                    v-for="(value, key) of AOIData"
                    v-show="key !== 'information' && key !== 'uuid'"
                    :key="key"
                    style="font-size: 15px"
                  >
                    <b class="text-capitalize">{{ key }}:</b>
                    {{ key === 'area' ? transformArea(value) : value }}
                  </div>
                </v-layout>
                <div v-if="AOIGeojson" class="mt-2 px-1" style="position: relative; width: 100%; padding-top: 55%">
                  <div
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      width: 100%;
                      height: 150px;
                      border-radius: 8px;
                      z-index: 1;
                    "
                  >
                    <MapPreview :id="'AOI'" :geojson="AOIGeojson" />
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-overlay :value="loading">
              <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular
            ></v-overlay>
            <v-layout align-center column style="height: fit-content">
              <div class="pt-2 px-3" style="width: 100%">
                <v-select
                  v-model="selectedDate"
                  :disabled="loading"
                  :items="labels"
                  dense
                  hide-details
                  label="Date"
                  outlined
                  placeholder="Date"
                  @change="getStatistic"
                ></v-select>
              </div>
              <!--              <div class="py-1 px-0" style="width: 100%">-->
              <!--                <v-layout style="font-size: 12px" align-center justify-center>-->
              <!--                  Compare-->
              <!--                  <v-icon>mdi-swap-vertical</v-icon>-->
              <!--                </v-layout>-->
              <!--              </div>-->
              <div class="pt-2 px-3" style="width: 100%">
                <v-text-field
                  v-model="oldDate"
                  dense
                  hide-details
                  label="Compare Date"
                  outlined
                  placeholder="Compare Date"
                  readonly
                ></v-text-field>
              </div>
            </v-layout>
            <div v-if="statistic" class="pt-1 px-5" style="flex: none; width: 100%; height: fit-content">
              <div style="height: 24px; font-size: 14px">
                <span>
                  <b class="pr-1">Max Upward:</b> {{ statistic.max > 0 ? statistic.max.toFixed(2) : 0 }}
                  <span style="font-size: 12px">mm</span></span
                >
              </div>
              <div style="height: 24px; font-size: 14px">
                <span>
                  <b class="pr-1">Max Downward:</b> {{ statistic.min < 0 ? statistic.min.toFixed(2) : 0 }}
                  <span style="font-size: 12px">mm</span></span
                >
              </div>
              <div style="height: 24px; font-size: 14px">
                <span> <b class="pr-1">Average:</b> {{ statistic.average.toFixed(2) }} </span>
                <span style="font-size: 12px">mm</span>
              </div>
              <div style="height: 24px; font-size: 14px">
                <span>
                  <b class="pr-1">Total:</b> {{ statistic.total }}
                  <span style="font-size: 12px">points</span>
                </span>
              </div>
            </div>
            <v-spacer />
            <v-divider />
            <div class="pa-2" style="flex: none; height: 20px; width: 100%">
              <v-layout align-center>
                <v-spacer />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" x-small>
                      <v-icon size="20" @click="fullScreen">mdi-arrow-expand</v-icon>
                    </v-btn>
                  </template>
                  <span>Fullscreen</span>
                </v-tooltip>
              </v-layout>
            </div>
            <div
              v-show="data.length > 0"
              id="chart-left"
              class="pa-3 pt-2"
              style="flex: none; height: 300px; width: 100%"
            >
              <canvas id="primaryChart" height="auto" width="100%"></canvas>
            </div>
          </v-layout>
        </v-card>
      </div>
      <v-layout class="fill-height pl-4" column>
        <div class="pb-2" style="width: 100%; flex: none">
          <v-layout class="fill-height">
            <v-col ref="AOI" class="d-flex align-center pt-4 pl-0" cols="12" lg="4">
              <select2
                v-model="currentAOI"
                :items="listAOI"
                dense
                hide-details
                item-text="name"
                item-value="uuid"
                label="Select AOI"
                outlined
                placeholder="AOI"
                return-object
                @change="getService"
              />
              <!--              <v-select-->
              <!--                v-model="currentAOI"-->
              <!--                :items="listAOI"-->
              <!--                :loading="loading"-->
              <!--                dense-->
              <!--                hide-details-->
              <!--                item-text="name"-->
              <!--                label="Select AOI"-->
              <!--                outlined-->
              <!--                placeholder="AOI"-->
              <!--                return-object-->
              <!--                @change="getService"-->
              <!--              />-->
            </v-col>
            <v-col ref="source" class="d-flex align-center pt-4" cols="12" lg="4">
              <v-select
                v-model="source"
                :disabled="!Object.keys(service).length"
                :items="sources"
                :loading="loading"
                dense
                hide-details
                item-text="name"
                label="Select source"
                outlined
                placeholder="Source"
                return-object
                @change="getListResult"
              />
            </v-col>
            <v-col ref="result" class="align-center pt-4" cols="12" lg="4">
              <v-select
                v-model="result"
                :disabled="!source"
                :items="data"
                :loading="loading"
                dense
                hide-details
                item-text="date"
                label="Date"
                outlined
                placeholder="Date"
                return-object
                @change="filterData"
              >
              </v-select>
            </v-col>
            <v-col class="align-center pt-4" cols="12" lg="0"></v-col>
          </v-layout>
        </div>
        <v-card height="100%" style="position: relative" width="100%">
          <Map ref="map" :current-layers.sync="layers" :isDraw="false" @featureInfo="getFeatureInfo" />
          <div style="width: 55px; height: 280px; max-height: 90%; position: absolute; top: 10px; left: 20px">
            <div style="width: 10px; height: 100%" v-bind:style="{ 'background-image': gradient }"></div>
            <div
              v-for="(item, index) in gradientColor"
              v-show="index % 2 === 0"
              style="
                position: absolute;
                right: 0;
                width: 100%;
                height: 1px;
                border: 1px solid #eaeaea;
                font-size: 11px;
                color: #eaeaea;
                text-align: end;
              "
              v-bind:style="{ top: 100 - index * 5 + '%' }"
            >
              {{ item.value.toFixed(2) }}
              <!-- {{ gradientColor.length }} -->
            </div>
          </div>
          <TourGuide
            style="position: absolute; bottom: 40px; right: 125px; z-index: 2"
            ref="tourGuide"
            :color="'#e3e3e3'"
            :iconColor="'#66808d'"
            v-if="elements"
            :elements="elements"
          />
        </v-card>
        <div class="pt-4" style="width: 100%; max-height: 300px; overflow: hidden; flex: none">
          <v-card class="custom-card-bg-1 position-relative" height="100%" width="100%">
            <v-layout class="fill-height pa-2" column>
              <div style="flex: none; width: 100%; height: fit-content; min-height: 0">
                <v-layout align-center class="fill-height">
                  <div v-if="!chart">No data</div>
                  <v-spacer />
                  <v-btn :disabled="!chart" icon small @click="changeDisplayChart">
                    <v-icon>{{ showable ? 'icon-chevrons_down' : 'icon-chevrons_up' }}</v-icon>
                  </v-btn>
                </v-layout>
              </div>
              <v-layout v-show="showable" align-center style="flex: 1; min-height: 230px">
                <div style="width: 275px; height: 100%; border-right: 1px solid #2f3241">
                  <v-layout align-center class="fill-height pl-3">
                    <v-card v-if="infoFeature" class="custom-card-bg-0 position-relative" width="100%">
                      <h3 class="py-2">Feature information</h3>
                      <div class="py-2">Longitude: {{ infoFeature.lng }}</div>
                      <div class="py-2">Latitude: {{ infoFeature.lat }}</div>
                      <div class="py-2">
                        Value: {{ infoFeature.currentValue.toFixed(2) }}
                        <span style="font-size: 12px">mm</span>
                      </div>
                      <div class="py-2">
                        Average: {{ infoFeature.avg.toFixed(2) }}
                        <span style="font-size: 12px">mm</span>
                      </div>
                    </v-card>
                  </v-layout>
                </div>
                <v-layout class="fill-height pl-2" style="flex: 1">
                  <canvas id="chart" height="auto" width="100%"></canvas>
                </v-layout>
              </v-layout>
            </v-layout>
          </v-card>
        </div>
      </v-layout>
    </v-layout>
    <MakeOrder ref="makeOrder" isOnScreenDialog />
  </v-layout>
</template>

<script>
import Map from '@/components/Map'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import { mapState } from '@/store/ults'
import { getChangeDetectionResult, getFeature } from '@/api/change-detection-api'
import { getOrder } from '@/api/order'
import { getDetailAOI } from '@/api/aoi-api'

import {
  getChartLandSubsidence,
  getFeatureInfoLandSubsidence,
  getLandSubsidenceLayer,
  getStatisticLandSubsidence,
} from '@/api/landsubsidence-api'
import AreaConvert from '@/utils/textArea'
import sleep from '@/utils/sleep'
import bbox from '@turf/bbox'
import CustomIcon from '@/components/CustomIcon.vue'
import MapPreview from '@/components/MapPreview.vue'
import Chart from 'chart.js/auto'
import GradientColor from '@/utils/genGradiendColor'
import Calculate from '@/utils/mathCulculate'
import utils from '@/utils/genUUID'
import Select2 from '@/components/Select2/Select2.vue'
import ElemFullScreen from '@/utils/ElemFullScreen'
import TourGuide from '@/components/GuideTour/index.vue'
import MakeOrder from '@/views/aoi/order/MakeOrder.vue'
export default {
  name: 'LandSubsidence',
  components: { Select2, MapPreview, CustomIcon, LayerControl, Map, TourGuide, MakeOrder },
  data() {
    return {
      chart: undefined,
      primaryChart: undefined,
      labels: [],
      chartData: [],
      AOIData: undefined,
      AOIGeojson: undefined,
      showable: false,
      statistic: undefined,
      dataLoading: false,
      loading: false,
      layers: [],
      source: undefined,
      currentAOI: undefined,
      leftMenu: true,
      sources: [],
      service: {},
      data: [],
      result: undefined,
      changeData: [],
      infoFeature: undefined,
      gradientColor: [],
      filterStyle: [],
      firstLoad: true,
      selectedDate: undefined,
      image: undefined,
      oldDate: undefined,
      elements: undefined,
    }
  },
  props: {},
  computed: {
    ...mapState('AOI', ['AOIs', 'listAOI']),
    gradient() {
      let colors = [...this.gradientColor].reverse()
      return 'linear-gradient(' + colors.map(val => val.color).toString() + ')'
    },
  },
  beforeDestroy() {
    if (this.$refs.tourGuide) this.$refs.tourGuide.completeTour()
  },
  async mounted() {
    await this.getListAOI()
    this.elements = [
      {
        ref: this.$refs.AOI,
        id: 'AOI',
        title: 'Getting Started',
        text:
          this.listAOI.length > 0
            ? 'Select an AOI to get results'
            : "You don't have any AOIs with results. Please submit an order.",
        position: 'bottom',
        isHideContinueBtn: this.listAOI.length > 0 ? undefined : true,
        buttons:
          this.listAOI.length > 0
            ? undefined
            : [
                {
                  text: 'Submit Order',
                  action: () => {
                    this.$refs.tourGuide.completeTour()
                    this.$refs.makeOrder.openDialog(null, this.$route.meta.service)
                  },
                },
              ],
      },
      {
        ref: this.$refs.source,
        id: 'source',
        title: 'Getting Started',
        text: 'Select a source of result',
        position: 'bottom',
      },
      {
        ref: this.$refs.result,
        id: 'result',
        title: 'Getting Started',
        text: this.data.length > 0 ? 'Select a result to show' : 'No result found, please check your order',
        position: 'bottom',
        buttons:
          this.data.length > 0
            ? undefined
            : [
                {
                  text: 'Check order',
                  action: () => {
                    this.$refs.tourGuide.completeTour()
                    this.$router.push({
                      name: 'orders',
                      query: {
                        aoiId: this.currentAOI.uuid,
                        service: this.service.service_id,
                        source: this.source,
                      },
                    })
                  },
                },
              ],
      },
    ]
  },
  watch: {
    selectedDate(val) {
      // let index = this.labels.findIndex(date => date === val)
      // if (index > 0) this.oldDate = this.labels[index - 1]
    },
    showable() {
      this.$refs.map.reSize()
    },
    options: {
      handler() {
        this.getData()
      },
      deep: true,
    },
    leftMenu() {
      this.$refs.map.reSize()
    },
  },
  methods: {
    async addToMap(AOI) {
      try {
        this.loading = true

        // this.$refs.map.addGeoJsonToMap(AOI.name, AOI.geojson, 'yellow', AOI.uuid, 'fill', true,'fill', {
        //     'fill-color': 'yellow',
        //     'fill-opacity': 0.5,
        //   })
        this.$refs.map.addGeoJsonToMap(AOI.name, AOI.geojson, 'yellow', AOI.uuid, 'line', true)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    fullScreen() {
      ElemFullScreen.setFullscreen('chart-left')
    },
    changeDisplayChart() {
      this.showable = !this.showable
    },
    async getFeatureInfo(features) {
      try {
        this.loading = true
        try {
          const res = await getFeatureInfoLandSubsidence({
            id: this.currentAOI.uuid,
            projectId: this.$route.params.id,
            featureId: features[0].properties.id,
            payload: { vector_uuid: this.result.uuid },
          })
          this.showable = true

          this.infoFeature = {
            currentValue: features[0].properties[this.selectedDate] * 1000,
            lng: res.data.info.geojson.coordinates[0],
            lat: res.data.info.geojson.coordinates[1],
            avg: Calculate.average(res.data.chart.values),
          }

          if (!this.chart) this.displayChart(res.data.chart, 'chart')
          else {
            this.transformData(res.data.chart)

            this.updateChartData(this.chartData, 'chart')
          }
        } catch (e) {
          console.log(e)
        } finally {
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListAOI() {
      try {
        this.resetData()
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_name: this.$route.meta.service },
        })
        if (this.firstLoad && this.AOIs.length) {
          this.currentAOI = this.AOIs[0]
        } else this.firstLoad = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getService() {
      this.chart = undefined
      this.showable = false
      try {
        this.loading = true
        this.resetData()
        this.$refs.map.removeAllLayer()
        this.$refs.map.submitZoom(this.currentAOI.bbox)
        const res = await getOrder({
          projectId: this.$route.params.id,
          id: this.currentAOI.uuid,
          payload: { service_name: this.$route.meta.service },
        })
        this.service = res.data[0]
        this.sources = []
        res.data.forEach(val => this.sources.push(val.image_source))

        if (this.sources.length) {
          this.source = this.sources[0]
          await this.getListResult()
        }
        await this.displayAOI()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListResult() {
      try {
        this.loading = true
        this.currentDate = {}
        this.compareDate = {}
        this.chartData = {}
        this.data = []
        const res = await getChangeDetectionResult({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            service_id: this.service.service_id,
            source: this.source,
            per_page: 'all',
          },
        })
        this.data = res.data
        if (this.data.length) {
          this.result = this.data[0]
          await this.filterData()
        }
      } catch (e) {
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    async filterData() {
      this.selectedDate = undefined
      this.gradientColor = []
      await this.getChart()
      await this.getStatistic()
    },
    async getStatistic() {
      try {
        this.loading = true
        this.gradientColor = []
        await sleep(100)
        if (!this.selectedDate) this.selectedDate = this.labels[this.labels.length - 1]
        console.log(this.selectedDate,this.labels)
        const res = await getStatisticLandSubsidence({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          payload: { date: this.selectedDate },
        })

        this.oldDate = res.data.master_date
        let colors = GradientColor.generate_colors(['#002cde', '#00d2b2', '#35c400', '#d2b700', '#c40000'], 21)
        const max = Math.abs(res.data.max) > Math.abs(res.data.min) ? Math.abs(res.data.max) : Math.abs(res.data.min)
        const min = Math.abs(res.data.max) > Math.abs(res.data.min) ? -Math.abs(res.data.max) : -Math.abs(res.data.min)
        let step = (max.toFixed(2) - min.toFixed(2)) / 20
        this.filterStyle = ['case']
        for (let i = 0; i < 21; i++) {
          this.gradientColor.push({
            color: colors[colors.length - i - 1].color,
            value: min + i * step,
          })
          if (i > 0)
            this.filterStyle.push(
              ['<=', ['get', this.selectedDate], (min + i * step) / 1000],
              colors[colors.length - i].color,
            )
        }
        this.filterStyle.push(colors[0].color)

        // let tile_url = res.data.tile_info.tile_url + '&color_map='
        // this.gradientColor.forEach((val, index) => {
        //   tile_url = tile_url + val.value / 1000 + ',' + val.color.replace('#', '')
        //   if (index < this.gradientColor.length - 1) tile_url = tile_url + ';'
        // })
        // this.image = {
        //   id: res.data.date_index + 'image',
        //   display: true,
        //   data: res.data.tile_info,
        //   layerType: 'Raster_Image',
        //   name: res.data.date_index,
        //   tiles: [tile_url],
        //   bounds: res.data.tile_info.bbox,
        // }
        this.statistic = res.data
        await this.getLayers()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getChart() {
      try {
        this.loading = true
        const res = await getChartLandSubsidence({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: { vector_uuid: this.result.uuid },
        })

        if (!this.primaryChart) {
          this.displayChart(res.data, 'primaryChart')
        } else {
          this.transformData(res.data)

          this.updateChartData(this.chartData, 'primaryChart')
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getLayers() {
      try {
        this.loading = true
        // this.$refs.map.removeAllLayer()
        let oldResultLayers = this.layers.filter(layer => layer.dataId)
        oldResultLayers.forEach(layer => this.$refs.map.removeLayer(layer.id))
        const res = await getLandSubsidenceLayer({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          payload: { source: this.source },
        })
        // this.$refs.map.addImageToMap(this.image)
        res.data.forEach((val, index) => {
          if (index === res.data.length - 1) val.tile_info.name = val.name
          if (val.tile_info.type === 'images') {
            let image = {
              id: val.name,
              display: true,
              data: val.tile_info,
              layerType: 'Raster_Image',
              name: val.name,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
            }
            this.$refs.map.addImageToMap(image)
          } else {
            this.$refs.map.addVectorTiles({
              display: true,
              data: val.tile_info,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
              layerName: 'default',
              paint: {
                'circle-color': this.filterStyle,
                'circle-opacity': 1,
                'circle-radius': 5,
                'circle-stroke-color': '#d2d2d2',
                'circle-stroke-opacity': 1,
                'circle-stroke-width': 2,
              },
              name: val.name,
              id: val.name + utils.getUUID(),
              type: val.tile_info.styles.type,
              isHover: true,
            })
            // let vector = this.layers.find(val => val.dataId === val.name)
            // this.$refs.map.addHoverToLayer(vector.id)
          }
        })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async addLayer(feature) {
      let featureDetail = await this.getFeature(feature)
      this.$refs.map.displayVector(
        'RED',
        featureDetail.geojson,
        featureDetail.geojson.type,
        'red',
        'change-id-' + featureDetail.id,
        false,
        featureDetail.geojson.type,
      )
      this.$refs.map.submitZoom(bbox(featureDetail.geojson))
      await sleep(800)
      this.$refs.map.removeLayer('change-id-' + featureDetail.id)
    },
    async getFeature(feature) {
      try {
        this.loading = true
        const res = await getFeature({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          featureId: feature.id,
          payload: {},
        })
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async displayAOI() {
      try {
        if (!this.currentAOI) this.AOIGeojson = undefined
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: this.currentAOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        this.AOIData = {
          area: this.currentAOI.area,
          name: this.currentAOI.name,
          group: this.currentAOI.group,
        }
        this.AOIGeojson = res.data.geojson
        this.addToMap(res.data)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    transformArea(area, withUnit = true) {
      return AreaConvert.covertArea(area, 'ha', withUnit)
    },
    resetData() {
      this.gradientColor = []
      this.selectedDate = undefined
      this.source = undefined
      this.changeData = []
      this.data = []
      this.result = undefined
    },
    transformData(chartInfo) {
      this.chartData = []
      this.labels = []
      this.labels = chartInfo.labels
      if (chartInfo.trending_line)
        this.chartData.push({
          type: 'line',
          label: '',
          data: chartInfo.trending_line,
          borderColor: 'yellow',
          borderWidth: 2,
          borderDash: [5, 5],
          pointBorderWidth: 0,
          pointHitRadius: 0,
          tension: 0.1,
        })
      this.chartData.push({
        type: 'line',
        label: '',
        data: chartInfo.values,
        backgroundColor: 'blue',
        borderColor: 'red',
        borderWidth: 2,
        pointBorderWidth: 4,
        pointHitRadius: 6,
        pointBackgroundColor: 'blue',
      })
    },
    displayChart(chartInfo, chartId) {
      this.transformData(chartInfo)
      const data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      let ctx = document.getElementById(chartId)
      this[chartId] = new Chart(ctx, {
        data: data,
        options: {
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            let currentImage = this.labels.find(item => item === data.labels[e.index])
            if (currentImage) {
              this.selectedDate = currentImage
              this.getStatistic()
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          title: {
            color: '#d4d4d4',
            display: true,
            text: '',
          },
          scales: {
            x: {
              ticks: {
                color: '#d4d4d4',
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: true,
              },
            },
            y: {
              ticks: {
                color: '#d4d4d4',
              },
              stacked: false,
              title: {
                color: '#d4d4d4',
                text: 'mm',
                display: true,
              },
              grid: {
                color: '#2F3241',
                display: true,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
    updateChartData(newData, chartId) {
      this[chartId].data.datasets = newData
      this[chartId].data.labels = this.labels
      this[chartId].update()
    },
  },
}
</script>

<style scoped></style>
